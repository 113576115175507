import React, {FunctionComponent} from 'react'
import {Link, RouteComponentProps} from 'react-router-dom'
import styles from './ResetPasswordError.module.css'
import logo from '../../../../../styles/common/logo.png'

const ResetPasswordError: FunctionComponent<RouteComponentProps> = ({history}: RouteComponentProps) => {
    const returnToLogin = (): void => {
        history.push('/login')
    }

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <Link to={'/'}><img src={logo} alt="Assembled Brands"/></Link>
                <div className={styles.title}>
                    An error occurred.
                </div>
                <p className={styles.message}>Your password reset link has expired.
                    Please try to reset your password again.</p>
                <button className={styles.button}
                        onClick={returnToLogin}>
                    Back to Log In
                </button>
            </div>
        </div>
    )
}

export default ResetPasswordError