import React, {FunctionComponent} from 'react'
import {Button, Grid, Modal, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Theme} from '@material-ui/core/styles/createTheme'
import theme from '../../../styles/materialTheme'

type AbModalProps = {
    children: any,
    isOpen: boolean,
    headline: string,
    isConfirmDisabled?: boolean, // True if the confirm button is visible but in disabled state
    isHideConfirm?: boolean, // True to hide the confirm button
    width?: number, // The width of the modal
    confirmLabel?: string,
    cancelLabel?: string,
    onConfirm?(): void, // executes when confirm button is pressed
    onCancel?(): void, // executes when cancel button is pressed if undefined hide cancel button
    onClose?(): void // executes when the modal closes(also after confirm and after cancel)
}

const AbModal: FunctionComponent<AbModalProps> = ({
                                                      isOpen,
                                                      headline,
                                                      onCancel,
                                                      isConfirmDisabled,
                                                      onConfirm,
                                                      onClose,
                                                      width,
                                                      confirmLabel,
                                                      cancelLabel,
                                                      isHideConfirm,
                                                      children
                                                  }: AbModalProps) => {
    const useStyles = makeStyles((theme: Theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100vw'
        },
        modalContent: {
            position: 'absolute',
            [theme.breakpoints.up('sm')]: {
                width: width ? width : 520
            },
            [theme.breakpoints.only('xs')]: {
                width: `calc(100vw - ${48}px)`
            },
            backgroundColor: theme.palette.background.paper,
            borderRadius: '4px',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 2, 1),
            '&:focus': {
                outline: 'none'
            }
        },
        modalButton: {
            marginLeft: theme.spacing(3.125)
        },
        modalContentChild: {
            marginBottom: theme.spacing(1),
            '&:last-child': {
                marginBottom: 'unset'
            }
        }
    }))

    const classes = useStyles(theme)

    const childElements = children.length ? children.filter((child: JSX.Element) => child) : [children]

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        className={classes.root}
        style={{zIndex: 1400}}
      >
          <Grid container direction="column" spacing={2} className={classes.modalContent}>
              <Grid item xs={12}>
                  <Typography variant="h6">{headline}</Typography>
              </Grid>
              <Grid container direction="column"
                    item xs={12} sm={12}>
                  {
                      childElements.map((child: JSX.Element, index: number) => {
                          return <Grid key={index}
                                       container direction="column"
                                       item xs={12} sm={12}
                                       className={classes.modalContentChild}>
                              {child}
                          </Grid>
                      })
                  }
              </Grid>
              <Grid container justify="flex-end"
                    item xs={12}>
                  {
                    onCancel &&
                    <Button
                      color="primary"
                      onClick={
                          (e): void => {
                              e.preventDefault()
                              if (onCancel) onCancel()
                              if (onClose) onClose()
                          }}
                    >
                        <Typography variant="button">{cancelLabel ? cancelLabel : 'Nevermind'}</Typography>
                    </Button>
                  }

                  {(isHideConfirm === undefined || !isHideConfirm) && <Button
                    color="primary"
                    disabled={isConfirmDisabled}
                    onClick={
                        (e): void => {
                            e.preventDefault()
                            if (onConfirm) onConfirm()
                            if (onClose) onClose()
                        }}
                    className={classes.modalButton}
                  >
                      <Typography variant="button">{confirmLabel ? confirmLabel : 'Got It'}</Typography>
                  </Button>}
              </Grid>
          </Grid>
      </Modal>
    )
}

export default AbModal