import http from '../services/http'
import analytics from '../services/analytics'
import analyticsMessages from '../analyticsMessages'
import {LoginFormData} from '../unauthenticated-app/login-form/LoginForm'
import {AxiosResponse} from 'axios'
import {CurrentUser} from './AuthProvider'

const getCurrentUser = (): Promise<CurrentUser> => {
    return http.get('/accounts/me').then((response) => {
        if (response.data.anonymous) {
            return Promise.reject()
        } else {
            return response.data
        }
    })
}

const login = (form: LoginFormData): Promise<void> => {
    const formData = new FormData()
    formData.set('username', form.email)
    formData.set('password', form.password)

    return http.post('/login', formData)
        .then(response => {
            if (!response.request.responseURL.includes('error')) {
                analytics.userEvent(analyticsMessages.USER_LOGGED_IN_SUCCESS)
            } else {
                return Promise.reject()
            }
        })
}

const logout = (): Promise<AxiosResponse> => http.post('/logout')

export default {getCurrentUser, login, logout}