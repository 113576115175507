import React, {FormEvent, FunctionComponent} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useAuth} from '../../../authentication/AuthProvider'
import accountMessages from '../../../accountMessages'
import createAccountFormValidator from './createAccountFormValidator'
import accountClient from '../accountClient'
import {Button, CircularProgress, Grid, Hidden, makeStyles, TextField, Theme, Typography} from '@material-ui/core'
import logo from '../../../../styles/common/ab-logo.png'
import headerImage from '../../../../styles/common/header-hero.jpeg'
import benefitsImage from '../../../../styles/common/benefits-hero.jpeg'
import analytics from '../../../services/analytics'
import theme from '../../../../styles/materialTheme'
import utmParser from './utmParser'
import queryString from 'query-string'

export type CreateAccountFormData = {
    fullName: string
    companyName: string
    email: string
    password: string
    confirmPassword: string
    termsAndConditions: boolean
    utmSource: string
    utmMedium: string
    utmCampaign: string
}

export type PrefillableFields = {
    email: string
    companyName: string
}

const useStyles = makeStyles((theme: Theme) => ({
    lightBackgroundSection: {
        padding: theme.spacing(3),
        height: '428px',
        [theme.breakpoints.down('xs')]: {
            width: '327px',
            height: '390px'
        },
        [theme.breakpoints.only('sm')]: {
            width: '384px',
            height: '450px'
        }
    },
    whiteBackgroundSectionContainer: {
        backgroundColor: 'white'
    },
    whiteBackgroundSection: {
        padding: theme.spacing(8, 3),
        maxWidth: '776px',
        [theme.breakpoints.only('xs')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6)
        },
        [theme.breakpoints.only('sm')]: {
            maxWidth: '384px'
        }
    },
    darkBackgroundSectionContainer: {
        backgroundColor: '#333333'
    },
    darkBackgroundSection: {
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(6.5, 4.5)
        },
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(6.5, 2.5)
        },
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(4.5, 1.5)
        },
        color: 'white'
    },
    formSection: {
        padding: theme.spacing(3),
        [theme.breakpoints.only('xs')]: {
            width: '327px'
        },
        [theme.breakpoints.up('sm')]: {
            width: '380px'
        }
    },
    title: {
        fontFamily: 'DTL Documenta',
        fontStyle: 'normal',
        fontSize: '46px',
        fontWeight: 'normal',
        lineHeight: '54px',
        letterSpacing: '1px',
        textAlign: 'left',
        marginBottom: theme.spacing(4.25),
        [theme.breakpoints.down('xs')]: {
            width: '327px',
            fontSize: '38px'
        },
        [theme.breakpoints.only('sm')]: {
            width: '280px',
            fontSize: '42px'
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '54px',
            lineHeight: '64px'
        }
    },
    subtitle: {
        marginBottom: theme.spacing(4.25),
        [theme.breakpoints.only('xs')]: {
            width: '327px'
        },
        [theme.breakpoints.only('sm')]: {
            width: '280px'
        }
    },
    sectionTitle: {
        marginBottom: theme.spacing(2)
    },
    startApplicationButton: {
        padding: theme.spacing(1.25, 3),
        width: '190px',
        color: 'white'
    },
    getStartedButton: {
        color: 'white !important',
        height: '48px',
        padding: theme.spacing(2, 8.5)
    },
    footer: {
        height: '80px'
    },
    footerOption: {
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(1.5)
    },
    logo: {
        marginBottom: theme.spacing(4)
    },
    benefitsList: {
        listStylePosition: 'inside',
        paddingInlineStart: '0',
        '& li': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    },
    removePadding: {
        padding: '0 !important'
    },
    form: {
        height: '552px'
    },
    termsAndConditionLabel: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4)
    },
    agree: {
        marginLeft: theme.spacing(1),
        margin: theme.spacing(4.25),
        '& a': {
            color: '#EC6D5A'
        }
    },
    headerHeroBackground: {
        backgroundImage: `url(${headerImage})`,
        backgroundPosition: 'center', /* Center the image */
        backgroundRepeat: 'no-repeat', /* Do not repeat the image */
        height: '100%',
        backgroundSize: 'cover'
    },
    benefitsHeroBackground: {
        backgroundImage: `url(${benefitsImage})`,
        backgroundPosition: 'center', /* Center the image */
        backgroundRepeat: 'no-repeat', /* Do not repeat the image */
        height: '100%',
        backgroundSize: 'cover'
    },
    error: {
        color: theme.palette.error.main,
        height: '40px',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
        marginleft: theme.spacing(2)
    },
    errorText: {
        [theme.breakpoints.only('xs')]: {
            fontSize: '13px !important'
        }
    },
    signInContainer: {
        marginTop: theme.spacing(2),
        '& a': {
            color: '#EC6D5A'
        }
    }
}))

const CreateAccountForm: FunctionComponent = () => {
    const classes = useStyles(theme)

    const utm = utmParser.parseUtm(useLocation().search, document.referrer)

    const parsePrefillableFields = (qs: string): PrefillableFields => {
        const parsed = queryString.parse(qs)

        const email = parsed.email ? parsed.email as string : ''
        const companyName = parsed.company_name ? parsed.company_name as string : ''

        return {email: email, companyName: companyName}
    }

    const prefillableFields: PrefillableFields = parsePrefillableFields(useLocation().search)
    const initialForm: CreateAccountFormData = {
        fullName: '',
        companyName: '',
        email: '',
        password: '',
        confirmPassword: '',
        termsAndConditions: false,
        utmMedium: utm.utmMedium,
        utmSource: utm.utmSource,
        utmCampaign: utm.utmCampaign
    }
    const [form, setForm] = React.useState(initialForm)
    const [validationError, setValidationError] = React.useState('')
    const [formSubmitting, setFormSubmitting] = React.useState(false)
    const {login} = useAuth()

    React.useEffect(() => {
        analytics.pageView(window.location.pathname + window.location.search)
        setForm(previousForm => ({
            ...previousForm,
            email: prefillableFields.email,
            companyName: prefillableFields.companyName
        }))
    }, [])

    const submitForm = (e: FormEvent): void => {
        e.preventDefault()
        setValidationError('')

        const formError = createAccountFormValidator.validateFormErrors(form)
        if (formError) {
            setValidationError(formError)
        } else if (!formSubmitting) {
            setFormSubmitting(true)
            accountClient.register(form)
                .then(() =>
                    login(form).then(() => {
                        localStorage.setItem('showWelcomeMessage', 'true')
                        window.location.href = '/application'
                    }))
                .catch(error => {
                    if (error.response.status === 409) {
                        setValidationError(accountMessages.USER_ALREADY_EXISTS)
                    } else {
                        setValidationError(accountMessages.UNKNOWN_ERROR)
                    }
                    setFormSubmitting(false)
                })
        }
    }


    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <div className={classes.lightBackgroundSection}>
                        <div className={classes.logo}><Link to={'/'}><img src={logo} alt="Assembled Brands"/></Link>
                        </div>
                        <div className={classes.title}>Apply today to grow your brand.</div>
                        <Typography variant="body1" className={classes.subtitle}>Unrivaled support for emerging companies building the best new brands.</Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.startApplicationButton}
                            href="#signup-form">
                            <Typography variant="button">START APPLICATION</Typography>
                        </Button>
                        <div className={classes.signInContainer}>
                            <Typography variant="body2">Have an account? <Link to={'/login'}>Sign In</Link></Typography>
                        </div>
                    </div>
                </Grid>

                <Hidden xsDown>
                    <Grid item sm={6} classes={{item: classes.removePadding}}>
                        <div className={classes.headerHeroBackground}/>
                    </Grid>
                </Hidden>

                <Grid container direction="column"
                      item xs={12} alignItems="center"
                      className={classes.whiteBackgroundSectionContainer}>
                    <div className={classes.whiteBackgroundSection}>
                        <Typography variant="h5" className={classes.sectionTitle}>Why Assembled Brands</Typography>
                        <Typography variant="body1">Assembled Brands is a growth platform for modern brands. We are
                            devoted
                            to helping consumer product
                            businesses grow. Not only do we provide lines of credit, but also access to financial models
                            and an
                            unrivaled network.
                        </Typography>
                    </div>
                </Grid>


                <Hidden xsDown>
                    <Grid item sm={6} classes={{item: classes.removePadding}}>
                        <div className={classes.benefitsHeroBackground}/>
                    </Grid>
                </Hidden>

                <Grid container direction="column"
                      item xs={12} sm={6}
                      className={classes.darkBackgroundSectionContainer}>
                    <div className={classes.darkBackgroundSection}>
                        <Typography variant="h5" className={classes.sectionTitle}>The Benefits</Typography>
                        <Typography variant="body1" component="div">
                            <ul className={classes.benefitsList}>
                                <li>Our support scales with you as you grow</li>
                                <li>Flexible structures with no financial covenants</li>
                                <li>Fast and easy application process</li>
                                <li>A member of the team with you every step of the way</li>
                                <li>Learn from our specialty knowledge of the DTC space</li>
                            </ul>
                        </Typography>
                    </div>
                </Grid>

                <Grid container direction="column" spacing={0} justify="center"
                      item xs={12} sm={12} alignItems="center"
                      className={classes.whiteBackgroundSectionContainer}>
                    <Grid container direction="column" spacing={0}
                          item alignItems="center"
                          className={classes.formSection}>
                        <Grid item>
                            <Typography variant="h4">
                                <a id="signup-form"/>
                                Let’s Get Started
                            </Typography>
                        </Grid>
                        {
                            <Grid item className={classes.error}>
                                <Typography variant="body1" align="center"
                                            className={classes.errorText}>{validationError}</Typography>
                            </Grid>
                        }
                        <Grid container direction="column" justify="space-between" spacing={0}
                              item alignItems="center"
                              className={classes.form}>
                            <TextField
                                fullWidth={true}
                                label="Your Email"
                                id="email"
                                name="email"
                                type="email"
                                required
                                value={form.email}
                                inputProps={{style: {borderBottom: '0px'}}}
                                onChange={(e): void => setForm({...form, email: e.target.value})}
                            />
                            <TextField
                                fullWidth={true}
                                id="companyName"
                                label="Brand Name"
                                type="text"
                                name="companyName"
                                required
                                value={form.companyName}
                                inputProps={{style: {borderBottom: '0px'}}}
                                onChange={(e): void => setForm({...form, companyName: e.target.value})}
                            />
                            <TextField
                                fullWidth={true}
                                id="fullName"
                                name="fullName"
                                type="text"
                                label="Your Name"
                                required
                                value={form.fullName}
                                inputProps={{style: {borderBottom: '0px'}}}
                                onChange={(e): void => setForm({...form, fullName: e.target.value})}
                            />
                            <TextField
                                fullWidth={true}
                                label="Password"
                                id="password"
                                name="password"
                                type="password"
                                required
                                value={form.password}
                                inputProps={{'data-testid': 'password', style: {borderBottom: '0px'}}}
                                onChange={(e): void => setForm({...form, password: e.target.value})}
                            />
                            <TextField
                                fullWidth={true}
                                label="Confirm Password"
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                required
                                value={form.confirmPassword}
                                inputProps={{'data-testid': 'confirmPassword', style: {borderBottom: '0px'}}}
                                onChange={(e): void => setForm({...form, confirmPassword: e.target.value})}
                            />
                            <label className={classes.termsAndConditionLabel}>
                                <input
                                    name="termsAndConditions"
                                    type="checkbox"
                                    value={form.termsAndConditions.toString()}
                                    onChange={(): void => setForm({
                                        ...form,
                                        termsAndConditions: !form.termsAndConditions
                                    })}
                                />
                                <span className={classes.agree}>
                    <Typography variant="body2" display="inline">
                      I agree to the <Link target="_blank" to="/termsOfUse">terms & conditions</Link>.
                    </Typography>
                  </span>
                            </label>
                            <Button onClick={submitForm}
                                    variant="contained"
                                    color="secondary"
                                    disabled={!form.termsAndConditions || formSubmitting}
                                    className={classes.getStartedButton}>
                                {!formSubmitting && <Typography variant="button">Get Started</Typography>}
                                {formSubmitting && <CircularProgress color="inherit" size="22px"/>}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container justify="flex-end"
                      item xs={12} sm={12} alignItems="center"
                      className={classes.footer}>
                    <Button
                        color="primary"
                        href="/termsOfUse"
                        target="_blank"
                        className={classes.footerOption}>
                        <Typography variant="button">Terms of Use</Typography>
                    </Button>
                    <Button
                        color="primary"
                        href="/privacyNotice"
                        target="_blank"
                        className={classes.footerOption}>
                        <Typography variant="button">Privacy Notice</Typography>
                    </Button>
                    <Button
                        color="primary"
                        href="/login"
                        className={classes.footerOption}>
                        <Typography variant="button">Sign In</Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default CreateAccountForm