import accountMessages from '../../../accountMessages'
import {CreateAccountFormData} from './CreateAccountForm'

const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/
    const emailParts = email.split('@')

    if (emailParts.length !== 2) return false
    const account = emailParts[0]
    const address = emailParts[1]

    if (account.length > 64) return false

    if (address.length > 255) return false

    const domainParts = address.split('.')
    if (domainParts.some((part) => {
        return part.length > 63
    })) return false

    return emailRegex.test(email)
}

const validateFormErrors = (form: CreateAccountFormData): string => {
    const fieldsArePopulated = form.fullName
        && form.companyName
        && form.email
        && form.password
        && form.confirmPassword
    if (!fieldsArePopulated) return accountMessages.FIELDS_REQUIRED

    if (!isValidEmail(form.email)) return accountMessages.INVALID_EMAIL
    if (form.password !== form.confirmPassword) return accountMessages.PASSWORD_MISMATCH

    return ''
}

export default {validateFormErrors}