import Bowser from 'bowser'

export const isUnsupportedBrowser = (): boolean => !Bowser
    .getParser(window.navigator.userAgent)
    .satisfies({
        // or in general
        chrome: '>1',
        firefox: '>1',
        windows: {
            'Microsoft Edge': '>1'
        },
        safari: '>1'
    })

export default {isUnsupportedBrowser}