import queryString from 'query-string'

export type Utm = {
    utmMedium: string
    utmSource: string
    utmCampaign: string
}

const parseUtm = (qs: string, referrer: string): Utm => {
    const parsed = queryString.parse(qs)

    let utmMedium = parsed.utm_medium ? parsed.utm_medium as string : ''
    let utmSource = parsed.utm_source ? parsed.utm_source as string : ''
    let utmCampaign = parsed.utm_campaign ? parsed.utm_campaign as string : ''

    if (utmMedium === '') {
        if (referrer === '') {
            return {utmMedium: 'direct', utmSource: 'direct', utmCampaign: 'direct'}
        }

        if (referrer.startsWith('https://www.google.com')) {
            utmMedium = 'organic'
            utmSource = 'google'
            utmCampaign = 'seo'
        } else if (referrer.startsWith('https://www.bing.com')) {
            utmMedium = 'organic'
            utmSource = 'bing'
            utmCampaign = 'seo'
        } else if (referrer.startsWith('https://www.yahoo.com')) {
            utmMedium = 'organic'
            utmSource = 'yahoo'
            utmCampaign = 'seo'
        } else if (referrer.startsWith('https://www.duckduckgo.com')) {
            utmMedium = 'organic'
            utmSource = 'duckduckgo'
            utmCampaign = 'seo'
        } else if (referrer.startsWith('https://assembledbrands.com')) {
            utmMedium = 'referral'
            utmSource = 'com-site'
            utmCampaign = 'assembledbrands'
        } else {
            utmMedium = 'referral'
            utmSource = referrer
            utmCampaign = 'unknown'
        }
    }

    return {utmMedium: utmMedium, utmSource: utmSource, utmCampaign: utmCampaign}
}

export default {parseUtm}