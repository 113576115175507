import axios, {AxiosResponse} from 'axios'

axios.defaults.withCredentials = true

const post = async (path: string, data?: any): Promise<AxiosResponse> =>
    await axios.post(path, data)

const get = async (path: string, params?: any): Promise<AxiosResponse> =>
    await axios.get(path, params)

export default {post, get}