import React, {FunctionComponent} from 'react'
import chrome from '../../../resources/chrome_128x128.png'
import firefox from '../../../resources/firefox_128x128.png'
import safari from '../../../resources/safari_128x128.png'
import edge from '../../../resources/edge_128x128.png'

const BrowserNotSupported: FunctionComponent = () => {
    return <div>
        <div className="row center-align">
            <div className="col s12">
                <p><i className="large material-icons blue-text darken-1-text">thumb_down</i></p>
                <h3 className="blue-text text-darken-2">PLEASE REOPEN THE LINK IN ONE OF THESE BROWSERS</h3>
                <p><a href="https://www.google.com/chrome/">
                    <img src={chrome} alt="Chrome"/><br/>
                    Google Chrome
                </a></p>
                <p><a href="https://www.mozilla.org/en-US/firefox/new/">
                    <img src={firefox} alt="Firefox"/><br/>
                    Firefox
                </a></p>
                <p><a href="https://support.apple.com/downloads/safari">
                    <img src={safari} alt="Safari"/><br/>
                    Safari
                </a></p>
                <p><a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
                    <img src={edge} alt="Edge"/><br/>
                    Microsoft Edge
                </a></p>
            </div>
        </div>
    </div>
}
export default BrowserNotSupported
