import React, {FunctionComponent} from 'react'
import {Link, RouteComponentProps} from 'react-router-dom'
import * as qs from 'query-string'
import accountMessages from '../../../../accountMessages'
import passwordClient from '../passwordClient'
import styles from './ResetPasswordForm.module.css'
import ResetPasswordSuccessModal from '../reset-password-success-modal/ResetPasswordSuccessModal'
import logo from '../../../../../styles/common/logo.png'

export type ResetPasswordFormData = {
    email: string
    password: string
    confirmPassword: string
    token: string | string[] | null | undefined
    showPasswordResetSuccess: boolean
}

const ResetPasswordForm: FunctionComponent<RouteComponentProps> = (
    {history}: RouteComponentProps
) => {
    const initialForm: ResetPasswordFormData = {
        email: '',
        password: '',
        confirmPassword: '',
        showPasswordResetSuccess: false,
        token: qs.parse(history.location.search).token
    }

    const initialValidationErrors = {
        passwordMismatch: false
    }

    const [form, setForm] = React.useState(initialForm)
    const [validationError, setValidationError] = React.useState(initialValidationErrors)

    React.useEffect(() => {
        passwordClient.validateToken(qs.parse(history.location.search).token)
            .then(email => {
                setForm({...form, email: email})
            })
            .catch(() => {
                history.push('/resetPasswordError')
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submitForm = (e: any): void => {
        e.preventDefault()
        if (form.password !== form.confirmPassword) {
            setValidationError({passwordMismatch: true})
        } else {
            passwordClient.resetPassword(form).then(() =>
                setForm({...form, showPasswordResetSuccess: true}))
        }
    }

    const handleClose = (): void => {
        setForm({...form, showPasswordResetSuccess: false})
        history.push('/login')
    }

    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <Link to={'/'}><img src={logo} alt="Assembled Brands"/></Link>
                <form className={styles.form} onSubmit={submitForm}>
                    {validationError.passwordMismatch &&
                    <div className={styles.error}>
                        <span>{accountMessages.PASSWORD_MISMATCH}</span>
                    </div>
                    }
                    <p className={styles.message}>Please type in new password for your <b>{form.email}</b> account.</p>
                    <div className="input-field">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            className="validate"
                            required
                            value={form.password}
                            onChange={(e): void => setForm({...form, password: e.target.value})}
                        />
                        <label htmlFor="password">New Password</label>
                    </div>
                    <div className="input-field">
                        <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            className="validate"
                            required
                            value={form.confirmPassword}
                            onChange={(e): void => setForm({...form, confirmPassword: e.target.value})}
                        />
                        <label htmlFor="confirmPassword">Retype Password</label>
                    </div>
                    <button className={styles.button} type="submit">Submit</button>
                </form>
            </div>
            {form.showPasswordResetSuccess &&
            <ResetPasswordSuccessModal isOpen={form.showPasswordResetSuccess}
                                       closeModal={handleClose}/>
            }
        </div>
    )
}

export default ResetPasswordForm