import http from '../../services/http'
import analytics from '../../services/analytics'
import analyticsMessages from '../../analyticsMessages'
import {CreateAccountFormData} from './create-account-form/CreateAccountForm'

const register = (form: CreateAccountFormData): Promise<void> => {
    return http.post('/accounts', form)
        .then(() => {
            analytics.userEvent(analyticsMessages.USER_CREATED_SUCCESS)
        })
}

export default {register}