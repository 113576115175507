import * as ReactGA from 'react-ga'

type trackingIdStrings = { [index: string]: string }

const trackingIds: trackingIdStrings = {
    'assembledbrands.app': 'UA-39620740-15',
    'assembledbrands.com': 'UA-39620740-8',
    'staging.assembledbrands.app': 'UA-39620740-16',
    'dev.assembledbrands.app': 'UA-39620740-17',
    'localhost': 'UA-39620740-17'
}

const initialize = (location: Location): void => {
    let testMode = true
    if (location.hostname === 'assembledbrands.app') {
        testMode = false
    }

    ReactGA.initialize([
            {
                trackingId: trackingIds[location.hostname],
                gaOptions: {
                    name: 'defaultTracker',
                    siteSpeedSampleRate: 100
                }
            },
            {
                trackingId: trackingIds['assembledbrands.com'],
                testMode: testMode,
                gaOptions: {
                    name: 'marketingTracker'
                }
            }
        ],
        {alwaysSendToDefaultTracker: false}
    )
}

const pageView = (pathname: string): void => {
    if (pathname && pathname.length > 0) {
        ReactGA.pageview(pathname, ['defaultTracker', 'marketingTracker'])
    }
}

const setAnalyticsUserId = (userId: string | null): void => {
    ReactGA.set({userId: userId}, ['defaultTracker', 'marketingTracker'])
}

const userEvent = (action: string): void => ReactGA.event({
    category: 'User',
    action
}, ['defaultTracker', 'marketingTracker'])

export default {initialize, userEvent, setAnalyticsUserId, pageView}