import React, {FunctionComponent} from 'react'
import http from '../../../../services/http'
import {Button, Grid, makeStyles, TextField, Typography} from '@material-ui/core'
import AbModal from '../../../../reusable-components/ab-modal/AbModal'
import theme from '../../../../../styles/materialTheme'

type ForgotPasswordModalProps = {
    isOpen: boolean
    closeModal(): void
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& .Mui-error': {
            color: '#960C00'
        },
        '& .MuiFormHelperText-root': {
            color: '#960C00'
        },
        '& .Mui-error:after': {
            borderBottomColor: '#960C00',
            borderWidth: 2,
            color: '#960C00'
        }
    },
    modalContent: {
        color: 'rgba(0,0,0,.6)'
    },
    successContent: {
        color: 'rgba(0,0,0,.6)',
        marginBottom: theme.spacing(4)
    },
    submitButtonContainer: {
        paddingTop: theme.spacing(6)
    },
    emailFieldContainer: {
        height: '40px',
        marginTop: theme.spacing(2)
    }
}))

const ForgotPasswordModal: FunctionComponent<ForgotPasswordModalProps> = (
    {isOpen, closeModal}: ForgotPasswordModalProps
) => {

    const classes = useStyles(theme)

    const [form, setForm] = React.useState({email: '', isSubmitSuccess: false})

    const submitForm = (): void => {
        http.post('/api/forgotPassword', form).then(() => {
            setForm({...form, isSubmitSuccess: true})
        }).catch(() => {
            setForm({...form, isSubmitSuccess: false})
        })
    }

    const validateEmail = (email: string): boolean => {
        return email.length === 0 || /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    }

    return (
        <>
            <AbModal
                isOpen={isOpen}
                headline={!form.isSubmitSuccess ? 'Forgot your Password?' : 'Reset email sent.'}
                onClose={closeModal}
                isHideConfirm={!form.isSubmitSuccess ? true : false}
                width={280}
            >
                <Grid container direction="column">
                    {
                        form.isSubmitSuccess &&
                        <Typography variant="body1" className={classes.successContent}>
                            If you entered your email correctly then we just sent you an email with a link to reset your
                            password.
                            Please follow the instructions in the email.
                        </Typography>
                    }
                    {
                        !form.isSubmitSuccess && <Grid container direction="column" className={classes.modalContent}>
                            <Typography variant="body1">
                                Enter your email to receive your password reset link.
                            </Typography>
                            <Grid container className={classes.emailFieldContainer}>
                                <TextField
                                    error={
                                        !validateEmail(form.email)
                                    }
                                    helperText={
                                        !validateEmail(form.email) ? 'Please enter a valid email address' : ''
                                    }
                                    fullWidth={true}
                                    label="Email"
                                    id="forgotPasswordEmail"
                                    name="forgotPasswordEmail"
                                    type="email"
                                    required
                                    value={form.email}
                                    inputProps={{style: {borderBottom: '0px'}}}
                                    onChange={(e): void => setForm({...form, email: e.target.value})}
                                />
                            </Grid>
                            <Grid container justify="flex-end" className={classes.submitButtonContainer}>

                                <Button
                                    disabled={form.email.length === 0 || !validateEmail(form.email)}
                                    color="primary"
                                    onClick={submitForm}>
                                    <Typography variant="button">Submit</Typography>
                                </Button>
                            </Grid>


                        </Grid>}
                </Grid>
            </AbModal>
        </>
    )
}

export default ForgotPasswordModal