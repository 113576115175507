import React, {FunctionComponent} from 'react'
import Modal from 'react-modal'
import styles from './ResetPasswordSuccessModal.module.css'
import submitSuccess from '../../../../../resources/submit-success.png'

type ResetPasswordSuccessModalProps = {
    isOpen: boolean
    closeModal(): void
}

const ResetPasswordSuccessModal: FunctionComponent<ResetPasswordSuccessModalProps> = (
    {isOpen, closeModal}: ResetPasswordSuccessModalProps
) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className={styles.modal}
            overlayClassName={styles.overlay}
            contentLabel="Reset Password Success Modal">

            <i className={`material-icons ${styles.closeIcon}`}
               onClick={closeModal}>close</i>
            <img className={styles.image} src={submitSuccess}/>
            <div className={styles.title}>
                Password change successful
            </div>
            <p className={styles.message}>Password change successful, please sign in with your new password</p>
            <button className={styles.button}
                    onClick={closeModal}>Sign In
            </button>
        </Modal>

    )
}

export default ResetPasswordSuccessModal
