import React from 'react'
import ReactDOM from 'react-dom'
import App from './main/App'
import * as Sentry from '@sentry/browser'
import analytics from './main/services/analytics'

Sentry.init({dsn: 'https://fa6a990a6de444c6b6630d8daa5aae23@sentry.io/1783222'})
analytics.initialize(window.location)

ReactDOM.render(<App/>, document.getElementById('root'))
