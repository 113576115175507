import React, {FunctionComponent, ReactElement} from 'react'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import LoginForm from './login-form/LoginForm'
import CreateAccountForm from './account/create-account-form/CreateAccountForm'
import ResetPasswordForm from './account/password/reset-password-form/ResetPasswordForm'
import ResetPasswordError from './account/password/reset-password-error/ResetPasswordError'
import BrowserNotSupported from './browser-not-supported/BrowserNotSupported'
import TermsOfUse from './legal-documents/TermsOfUse'
import PrivacyNotice from './legal-documents/PrivacyNotice'
import {isUnsupportedBrowser} from '../services/browserDetection'

const UnauthenticatedApp: FunctionComponent = () => {

    const [state, setState] = React.useState({unsupportedBrowser: false})

    React.useEffect(() => {
        setState(previousState => ({...previousState, unsupportedBrowser: isUnsupportedBrowser()}))
    }, [])

    return (
        <Router>
            <div>
                {state.unsupportedBrowser && <BrowserNotSupported/>}
                {!state.unsupportedBrowser &&
                <Switch>
                    <Route exact path="/" render={(): ReactElement => <Redirect to="/createAccount"/>}/>
                    <Redirect from="/referral"
                              to="/createAccount?utm_source=email&utm_medium=referral&utm_campaign=origination"/>
                    <Route exact path="/login" component={LoginForm}/>
                    <Route exact path="/createAccount" component={CreateAccountForm}/>
                    <Route exact path="/resetPassword" component={ResetPasswordForm}/>
                    <Route exact path="/resetPasswordError" component={ResetPasswordError}/>
                    <Route exact path="/termsOfUse" component={TermsOfUse}/>
                    <Route exact path="/privacyNotice" component={PrivacyNotice}/>
                    <Redirect to="/"/>
                </Switch>
                }
            </div>
        </Router>
    )
}

export default UnauthenticatedApp