import React, {FormEvent, FunctionComponent} from 'react'
import {useAuth} from '../../authentication/AuthProvider'
import {Button, CircularProgress, Grid, makeStyles, TextField, Theme, Typography} from '@material-ui/core'
import logo from '../../../styles/common/ab-logo.png'
import theme from '../../../styles/materialTheme'
import ForgotPasswordModal from '../account/password/forgot-password-modal/ForgotPasswordModal'
import analytics from '../../services/analytics'

export type LoginFormData = {
    email: string
    password: string
    rememberMe: boolean
    showForgottenPassword: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .Mui-error': {
            color: '#960C00'
        },
        '& .MuiFormHelperText-root': {
            color: '#960C00'
        },
        '& .Mui-error:after': {
            borderBottomColor: '#960C00',
            borderWidth: 2,
            color: '#960C00'
        }
    },
    container: {
        height: '100vh'
    },
    header: {
        backgroundColor: '#FCF0E9',
        padding: theme.spacing(3),
        '& img': {
            height: '32px'
        }
    },
    formContainer: {
        backgroundColor: 'white',
        flexGrow: 1
    },
    formSection: {
        padding: theme.spacing(3)
    },
    formTitle: {
        marginTop: theme.spacing(5.25)
    },
    form: {
        width: '380px',
        [theme.breakpoints.only('xs')]: {
            width: '327px'
        }
    },
    signInButton: {
        height: '48px',
        padding: '16px 69px',
        marginTop: '42px'
    },
    passwordField: {
        marginTop: '24px'
    },
    error: {
        color: '#960C00',
        height: '32px',
        marginTop: '8px',
        marginBottom: '12px',
        width: '400px',
        [theme.breakpoints.only('xs')]: {
            width: '360px'
        }
    },
    forgotPasswordLink: {
        fontFamily: 'Futura Std',
        color: '#FB7C6A',
        cursor: 'pointer'
    },
    loginOptions: {
        margin: '30px 0',
        '& div': {
            marginBottom: '12px'
        }
    },
    footer: {
        height: '80px'
    },
    footerOption: {
        marginLeft: theme.spacing(2.5),
        marginRight: theme.spacing(1.5)
    }
}))

const LoginForm: FunctionComponent = () => {
    const classes = useStyles(theme)

    const rememberedUser = localStorage.getItem('rememberMeEmail')
    const initialState: LoginFormData = {
        email: rememberedUser ? rememberedUser : '',
        password: '',
        rememberMe: !!rememberedUser,
        showForgottenPassword: false
    }

    const [form, setForm] = React.useState(initialState)
    const {login, accountError} = useAuth()
    const [formSubmitting, setFormSubmitting] = React.useState(false)

    React.useEffect(() => {
        analytics.pageView(window.location.pathname + window.location.search)
    }, [])

    const openForgottenPasswordModal = (): void => {
        setForm(previousState => ({...previousState, showForgottenPassword: true}))
    }

    const handleClose = (): void => {
        setForm(previousState => ({...previousState, showForgottenPassword: false}))
    }

    const submitForm = (e: FormEvent): void => {
        setFormSubmitting(true)
        e.preventDefault()

        login(form)
            .then(() => {
                if (form.rememberMe) {
                    localStorage.setItem('rememberMeEmail', form.email)
                } else {
                    localStorage.removeItem('rememberMeEmail')
                }

                window.location.href = '/'
                setFormSubmitting(false)
            })
            .catch(() => {
                setFormSubmitting(false)
            })
    }

    const validateEmail = (email: string): boolean => {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    }

    return (
        <Grid container direction="column" className={classes.container}>
            <Grid container
                  item alignItems="center"
                  className={classes.header}>
                <img src={logo} alt="Assembled Brands"/>
            </Grid>
            <Grid container direction="column"
                  item alignItems="center"
                  className={classes.formContainer}>
                <Grid container direction="column"
                      item alignItems="center"
                      className={classes.formSection}>
                    <Typography variant="h4" className={classes.formTitle}>
                        <a id="signup-form"/>
                        Welcome Back
                    </Typography>
                    {
                        <Typography variant="body2" className={classes.error}>
                            {accountError.message}
                        </Typography>
                    }
                    <Grid container direction="column"
                          item alignItems="center">
                        <form className={classes.form} onSubmit={submitForm}>
                            <Grid container direction="column" alignItems="center">
                                <TextField
                                    error={
                                        form.email.length > 0 && !validateEmail(form.email)
                                    }
                                    helperText={
                                        form.email.length > 0 && !validateEmail(form.email) ? 'Please enter a valid email address' : ''
                                    }
                                    fullWidth={true}
                                    label="Email"
                                    id="email"
                                    name="email"
                                    type="email"
                                    classes={{root: classes.root}}
                                    value={form.email}
                                    onChange={(e): void => setForm({...form, email: e.target.value})}
                                />

                                <TextField
                                    className={classes.passwordField}
                                    fullWidth={true}
                                    label="Password"
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={form.password}
                                    inputProps={{'data-testid': 'password'}}
                                    onChange={(e): void => setForm({...form, password: e.target.value})}
                                />
                                <Button type="submit"
                                        variant="contained"
                                        color="secondary"
                                        disabled={form.email.length === 0 || form.password.length === 0 || formSubmitting || !validateEmail(form.email)}
                                        className={classes.signInButton}>
                                    {!formSubmitting && <Typography variant="button">Sign In</Typography>}
                                    {formSubmitting && <CircularProgress color="inherit" size="22px"/>}
                                </Button>
                                <Grid container justify="center"
                                      className={classes.loginOptions}>
                                    <div className={`${classes.forgotPasswordLink} forgotPasswordLink`}
                                         onClick={openForgottenPasswordModal}>
                                        Forgot password?
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Grid>
            <div>
                <Grid container justify="flex-end"
                      item xs={12} sm={12} alignItems="center"
                      className={classes.footer}>
                    <Button
                        color="primary"
                        href="/termsOfUse"
                        target="_blank"
                        className={classes.footerOption}>
                        <Typography variant="button">Terms of Use</Typography>
                    </Button>
                    <Button
                        color="primary"
                        href="/privacyNotice"
                        target="_blank"
                        className={classes.footerOption}>
                        <Typography variant="button">Privacy Notice</Typography>
                    </Button>
                    <Button
                        color="primary"
                        href="/createAccount"
                        className={classes.footerOption}>
                        <Typography variant="button">Apply</Typography>
                    </Button>
                </Grid>
            </div>
            {form.showForgottenPassword &&
            <ForgotPasswordModal isOpen={form.showForgottenPassword}
                                 closeModal={handleClose}/>
            }
        </Grid>
    )
}

export default LoginForm