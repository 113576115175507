import * as React from 'react'
import {FunctionComponent} from 'react'
import authClient from './authClient'
import accountMessages from '../accountMessages'
import analytics from '../services/analytics'
import {LoginFormData} from '../unauthenticated-app/login-form/LoginForm'

export type CurrentUser = {
    company_id: string
    company_email: string
    brand_id: string
    full_name: string
    anonymous: boolean
    has_forecast_file: boolean
    stage: string
}

type AccountError = {
    message: string
}

type AuthContextProps = {
    user: CurrentUser
    accountError: AccountError
    login(form: Partial<LoginFormData>): Promise<void>
    logout(): Promise<void>
}

type AuthProviderProps = {
    children: React.ReactElement
}

const AuthContext = React.createContext<AuthContextProps | undefined>(undefined)

const AuthProvider: FunctionComponent<AuthProviderProps> = ({children}: AuthProviderProps) => {

    const existingUser = JSON.parse(localStorage.getItem('currentUser') || '{}')
    const [user, setUser] = React.useState(existingUser)
    const [accountError, setAccountError] = React.useState({message: ''})

    const getUser = (): Promise<void> => {
        return authClient.getCurrentUser()
            .then(user => {
                localStorage.setItem('currentUser', JSON.stringify(user))
                setUser(user)

                analytics.setAnalyticsUserId(user.brand_id)
            })
            .catch(() => {
                localStorage.removeItem('currentUser')
                setUser(undefined)

                analytics.setAnalyticsUserId(null)
            })
    }

    React.useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const login = (form: LoginFormData): Promise<void> => {
        return authClient.login(form)
            .then(() => getUser())
            .catch(() => {
                setAccountError({message: accountMessages.LOGIN_FAILURE})
                return Promise.reject()
            })
    }

    const logout = (): Promise<void> => authClient.logout().then(() => {
        localStorage.removeItem('currentUser')
        setUser(undefined)
    })

    return (
        <AuthContext.Provider value={{user, accountError, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuth = (): AuthContextProps => {
    const context = React.useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return context
}

export {AuthProvider, useAuth, AuthContext}