import * as React from 'react'
import {FunctionComponent} from 'react'
import featureFlagsClient from './featureFlagsClient'

type FeatureFlag = {
    unavailable: boolean,
    reporting: boolean
}

type FeatureFlagContextProps = {
    flags: FeatureFlag
}

type FeatureFlagProviderProps = {
    children: React.ReactElement
}

const FeatureFlagContext = React.createContext<FeatureFlagContextProps | undefined>(undefined)

const FeatureFlagProvider: FunctionComponent<FeatureFlagProviderProps> = ({children}: FeatureFlagProviderProps) => {

    const initialState = {
        unavailable: false,
        reporting: false
    }

    const [flags, setFlags] = React.useState(initialState)

    const getFeatureFlags = (): void => {
        featureFlagsClient.getFeatureFlags().then(response => {
            setFlags(() => response.data)
        })
    }

    React.useEffect(() => {
        getFeatureFlags()
    }, [])

    return (
        <FeatureFlagContext.Provider value={{flags}}>
            {children}
        </FeatureFlagContext.Provider>
    )
}

const useFeatureFlags = (): FeatureFlagContextProps => {
    const context = React.useContext(FeatureFlagContext)
    if (context === undefined) {
        throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider')
    }
    return context
}

export {FeatureFlagProvider, useFeatureFlags, FeatureFlagContext}
