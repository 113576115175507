import React, {FunctionComponent, Suspense} from 'react'
import {useAuth} from './AuthProvider'
import UnauthenticatedApp from '../unauthenticated-app/UnauthenticatedApp'

const AuthenticatedApp = React.lazy(() => import('../authenticated-app/AuthenticatedApp'))

const AuthRouter: FunctionComponent = () => {

    const {user} = useAuth()

    return (
        <>
            {(user && user.brand_id) ? (
                <Suspense fallback={UnauthenticatedApp}>
                    <AuthenticatedApp/>
                </Suspense>
            ) : <UnauthenticatedApp/>}
        </>
    )
}

export default AuthRouter