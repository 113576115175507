import React, {FunctionComponent} from 'react'
import {Link} from 'react-router-dom'

const TermsOfUse: FunctionComponent = () => (
    <div className="container">
        <h4>Terms and Conditions – Assembled Brands App</h4>

        <p>
            Assembled Brands is providing the application available at assembledbrands.app (the “Application”) as a
            means to all prospective clients to facilitate access to and the
            exchange of documents and other electronic materials. Specifically, the Application will enable users to
            upload information directly from your QuickBooks accounting software
            for access by the Assembled Brands underwriting team. These Terms and Conditions (“Terms”) govern your
            access to and use of the Application and any documents, data,
            information, and other content and materials (collectively, “Materials”) provided through the Application.
        </p>

        <p>
            When we refer to “you” and “your” in these Terms, we are referring to you as a user of the Application.
            “Assembled Brands,” “we,” “us,” and “our” refer to Assembled Brands
            Capital Funding, LLC and our affiliates.
        </p>

        <p>
            These Terms contain obligations users of the Application are expected to comply with. <b>YOU SHOULD
            CAREFULLY READ THESE TERMS. By downloading, accessing, or using the
            Application, you are creating a binding contract between you and us, and you are acknowledging that you have
            read, understood, and agree to be bound by these Terms.</b> If
            you do not agree to these Terms, you should not download, access, or otherwise use the Application.
        </p>

        <ol>

            <li>
                <p>
                    Use of the Application and access to Materials require you to have a login name and password. You
                    agree to keep this information confidential, and not share it with
                    any other person. Use of your login name and password by another person is strictly prohibited. You
                    are responsible for all activity and use of the Application that
                    occurs under your login name and password, including all Materials uploaded to the Application by
                    you or on your behalf. If you lose or forget your login information, or
                    if you believe your account has been compromised, you should notify us immediately at <a
                    href="mailto:hello@assembledbrands.com">hello@assembledbrands.com</a>.
                </p>
            </li>

            <li>
                <p>
                    By providing us with any Materials through the Application, you represent and warrant to us that you
                    have the authority to provide those Materials, and that all
                    information included in the Materials is true, accurate, current, and complete. If you need to
                    correct any information after providing Materials to us, please contact us
                    at <a href="mailto:hello@assembledbrands.com">hello@assembledbrands.com</a>.
                </p>
            </li>

            <li>
                <p>
                    You understand and agree that we will be using the Materials you provide through the Application to
                    make underwriting and credit decisions, and you authorize us to use
                    the Materials for those and any reasonably related purposes consistent with our Privacy Notice,
                    which is posted <Link to="/privacyNotice">here</Link>. You
                    understand that, once Materials have been uploaded to the Application, you will not be able to
                    download them, and we are under no obligation to return any Materials to
                    you. It is your responsibility to ensure that you maintain backup copies of all Materials uploaded
                    to the Application.
                </p>
            </li>

            <li>
                <p>
                    You acknowledge and agree that the Application is to be used only for legitimate purposes relating
                    to your business with Assembled Brands. Without limiting the
                    foregoing, you agree, represent, and warrant to us that:
                </p>

                <ol style={{listStyleType: 'lower-alpha'}}>

                    <li>
                        <p>
                            You will not copy, reproduce, distribute, sell, license, publish, or create derivative works
                            of any content made available to you through the Application.
                        </p>
                    </li>

                    <li>
                        <p>
                            You will not use the Application (i) to transmit any information or content that is illegal,
                            abusive, pornographic, obscene, or otherwise objectionable, or (ii) in
                            any way that may cause damage to, disable, or overburden the Application, impair its
                            functionality, or otherwise interfere with any other party’s ability to use the
                            Application.
                        </p>
                    </li>

                    <li>
                        <p>
                            You will not reverse engineer, disassemble, decompile, or tamper with the security of the
                            Application in any way.
                        </p>
                    </li>

                    <li>
                        <p>
                            You will not use the Application in any way that violates any laws or regulations, or in any
                            way that would infringe on or violate the rights of any third parties.
                        </p>
                    </li>

                </ol>

            </li>

            <li>
                <p>
                    The Application is designed to connect with QuickBooks, which is an accounting software offered by
                    Intuit, Inc. (“Intuit”). You understand that your use of QuickBooks
                    is governed by Intuit’s terms of use and privacy policy, and that Assembled Brands has no
                    responsibility for the functionality of QuickBooks or for any content, product
                    offerings, or other materials provided by Intuit, or for the activities of Intuit or any Intuit
                    affiliate. Any QuickBooks screen shots, the Intuit name, the Intuit
                    logo(s), the QuickBooks name, and the QuickBooks logo(s) are all the property of Intuit and/or its
                    affiliates.
                </p>
            </li>

            <li>
                <p>
                    YOU UNDERSTAND THAT YOUR USE OF THE APPLICATION IS WITHOUT WARRANTY OF ANY KIND AND THAT THE
                    APPLICATION IS PROVIDED TO YOU “AS IS” AND “WHERE IS.” ALL WARRANTIES ARE
                    DISCLAIMED INCLUDING IMPLIED WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, AND WARRANTIES BASED ON CUSTOM AND PRACTICE. APPLICABLE
                    LAW MAY NOT ALLOW THE ABOVE EXCLUSION OF IMPLIED WARRANTIES, SO THE EXCLUSION MAY NOT APPLY TO YOU
                    AND SHALL APPLY ONLY TO THE MAXIMUM EXTENT ALLOWED BY LAW. WE DO NOT
                    WARRANT THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, THAT ITS USE WILL BE UNINTERRUPTED,
                    TIMELY, SECURE, OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT
                    APPLICATION IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                </p>
            </li>

            <li>
                <p>
                    TO THE MAXIMUM EXTENT ALLOWED BY LAW, WE WILL NOT BE LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM
                    YOUR USE OF THE APPLICATION, INCLUDING DIRECT, CONSEQUENTIAL,
                    INCIDENTAL, PUNITIVE OR SPECIAL DAMAGES OR FOR LOST PROFITS OR ANY OTHER MONETARY LOSS. TO THE
                    EXTENT APPLICABLE LAW DOES NOT PERMIT THIS LIMITATION ON LIABILITY AND
                    DAMAGES, OUR LIABILITY AND EXPOSURE TO DAMAGES WILL BE LIMITED TO THE MAXIMUM EXTENT ALLOWED BY LAW.
                </p>
            </li>

            <li>
                <p>
                    You agree to defend, indemnify, and hold harmless Assembled Brands, its officers, directors,
                    employees, and agents from and against any and all claims, actions, or
                    demands which may arise out of or relate to any Materials you upload to or otherwise transmit
                    through the Application, your use of the Application, your violation of
                    these Terms, or your violation of any rights of any third party.
                </p>
            </li>

            <li>
                <p>
                    We may modify these Terms from time to time, in our sole discretion. Your continued use of the
                    Application constitutes your consent and agreement to be bound by the most
                    recent version of these Terms, whether or not you have reviewed them.
                </p>
            </li>

            <li>
                <p>
                    We may modify, suspend, or discontinue access to the Application at any time, and may remove
                    Materials from and/or block access to the Application for any reason, in its
                    sole discretion.
                </p>
            </li>

        </ol>

    </div>
)
export default TermsOfUse