export default {
    PLAID_CONNECTION_ATTEMPT: 'attempting to connect to Plaid',
    PLAID_CONNECTION_SUCCESS: 'successfully connected to Plaid',
    QUICKBOOKS_CONNECTION_ATTEMPT: 'attempting to connect to QuickBooks',
    QUICKBOOKS_CONNECTION_SUCCESS: 'successfully connected to QuickBooks',
    QUICKBOOKS_CONNECTION_FAILURE: 'unsuccessfully connected to QuickBooks',
    SHOPIFY_CONNECTION_SHOP_NAME_ENTRY_ATTEMPT: 'attempting to enter Shopify shop name',
    SHOPIFY_CONNECTION_ATTEMPT: 'attempting to connect to Shopify',
    SHOPIFY_CONNECTION_SUCCESS: 'successfully connected to Shopify',
    SHOPIFY_CONNECTION_FAILURE: 'unsuccessfully connected to Shopify',
    UPLOAD_BALANCE_SHEET_ATTEMPT: 'attempting to upload balance sheet file',
    UPLOAD_BALANCE_SHEET_SUCCESS: 'successfully uploaded balance sheet file',
    UPLOAD_PROFIT_AND_LOSS_ATTEMPT: 'attempting to upload profit and loss file',
    UPLOAD_PROFIT_AND_LOSS_SUCCESS: 'successfully uploaded profit and loss file',
    UPLOAD_ACCOUNTS_PAYABLE_ATTEMPT: 'attempting to upload accounts payable file',
    UPLOAD_ACCOUNTS_PAYABLE_SUCCESS: 'successfully uploaded accounts payable file',
    UPLOAD_ACCOUNTS_RECEIVABLE_ATTEMPT: 'attempting to upload accounts receivable file',
    UPLOAD_ACCOUNTS_RECEIVABLE_SUCCESS: 'successfully uploaded accounts receivable file',
    UPLOAD_REPEAT_CUSTOMER_ATTEMPT: 'attempting to upload repeat customer file',
    UPLOAD_REPEAT_CUSTOMER_SUCCESS: 'successfully uploaded repeat customer file',
    UPLOAD_MONTHLY_SALES_ATTEMPT: 'attempting to upload monthly sales file',
    UPLOAD_MONTHLY_SALES_SUCCESS: 'successfully uploaded monthly sales file',
    UPLOAD_INVESTOR_DECK_ATTEMPT: 'attempting to upload investor deck file',
    UPLOAD_INVESTOR_DECK_SUCCESS: 'successfully uploaded investor deck file',
    UPLOAD_FINANCIAL_PROJECTIONS_ATTEMPT: 'attempting to upload financial projections file',
    UPLOAD_FINANCIAL_PROJECTIONS_SUCCESS: 'successfully uploaded financial projections file',
    UPLOAD_INVENTORY_ATTEMPT: 'attempting to upload inventory file',
    UPLOAD_INVENTORY_SUCCESS: 'successfully uploaded inventory file',
    FILE_UPLOAD_SUBMIT: 'submitted files',
    FILE_UPLOAD_SUCCESS: 'successfully uploaded files',
    FILE_UPLOAD_FAILURE: 'unsuccessfully uploaded files',
    USER_LOGGED_IN_SUCCESS: 'successfully logged in',
    USER_CREATED_SUCCESS: 'successfully created a new user',
    USER_APPLICATION_SUBMIT_SUCCESS: 'successfully submitted application',
    CODAT_INTEGRATION_ATTEMPT: 'successfully checked codat integration',
    CODAT_CONNECTION_CHECK: 'attempting to check codat connection'
}