import React, {FunctionComponent} from 'react'

// import M from 'materialize-css'
import analytics from './services/analytics'
import {AuthProvider} from './authentication/AuthProvider'
import AuthRouter from './authentication/AuthRouter'
import Modal from 'react-modal'
import '../styles/common/index.scss'
import {FeatureFlagProvider} from './FeatureFlagProvider'
import {ThemeProvider} from '@material-ui/styles'
import theme from '../styles/materialTheme'

const App: FunctionComponent = () => {
    Modal.setAppElement('#root')

    React.useEffect(() => {

        // M.AutoInit()
        analytics.initialize(window.location)
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <FeatureFlagProvider>
                <AuthProvider>
                    <AuthRouter/>
                </AuthProvider>
            </FeatureFlagProvider>
        </ThemeProvider>
    )
}

export default App
