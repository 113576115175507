import http from '../../../services/http'
import {ResetPasswordFormData} from './reset-password-form/ResetPasswordForm'
import {AxiosResponse} from 'axios'

const validateToken = (token: string | string[] | null | undefined): Promise<string> => {
    return http.get(`/api/validateToken?token=${token}`)
        .then((response) => Promise.resolve(response.data.email))
}

const resetPassword = (form: ResetPasswordFormData): Promise<AxiosResponse> => http.post('/api/resetPassword', form)

export default {validateToken, resetPassword}