import React, {FunctionComponent} from 'react'

const PrivacyNotice: FunctionComponent = () => (
    <div className="container">

        <h4>Privacy Notice – Assembled Brands App</h4>

        <p>
            Assembled Brands respects the privacy of your personal information. This Privacy Notice (“Privacy Notice”)
            applies to information collected through the application made
            available at assembledbrands.app (the “Application”), and has been put in place so that you can better
            understand what information we collect through the Application, how we
            use it, and when and with whom we may share it.
        </p>

        <p>
            When we refer to “you” and “your” in this Privacy Notice, we are referring to you as a user of the
            Application. “Assembled Brands,” “we,” “us,” and “our” refer to Assembled
            Brands Capital Funding, LLC and our affiliates.
        </p>

        <p>
            <b>PLEASE READ THIS PRIVACY NOTICE COMPLETELY AND CAREFULLY BEFORE USING THE APPLICATION OR SUBMITTING ANY
                INFORMATION, PARTICULARLY PERSONAL INFORMATION, THROUGH THE
                APPLICATION. BY USING THE APPLICATION, YOU ARE AGREEING TO THIS PRIVACY NOTICE.</b>
        </p>


        <h5>1. APPLICABILITY OF THIS PRIVACY NOTICE</h5>

        <p>
            This Privacy Notice applies only to information provided and collected through the Application. It does not
            apply to information that we or others may collect through other
            means, such as information we may learn through a business relationship with you, or information you may
            provide to us through any other website we own or operate.
        </p>

        <p>
            Because of changes in Internet technology and practices, this Privacy Notice may change from time to time.
            It is our practice to post any changes we make to this Privacy
            Notice on this page, and any changes will be effective once the new notice is posted. It is your
            responsibility to check this Privacy Notice for updates. You will be able to
            determine when this Privacy Notice was last updated by referring to the “Last Modified” legend at the
            bottom. <b>By using the Application after we post any changes to this
            Privacy Notice, you agree to accept those changes, whether or not you have reviewed them.</b>
        </p>

        <h5>2. HOW WE COLLECT AND USE YOUR PERSONAL INFORMATION</h5>

        <p>
            Personal information refers to any information that identifies or can be used to identify a particular
            person, and as used in this Privacy Notice includes information such as
            your name, address, and email address, as well as your personal financial information, such as your bank
            records, tax returns, and financial statements.
        </p>

        <p>
            The Application has been provided as a means to allow you to directly provide us with access to documents,
            data, information, and other content and materials (“Materials”)
            from your QuickBooks accounting software for access by the Assembled Brands underwriting team. The Materials
            may be provided by you either by directly uploading the Materials
            to the Application, or through o-auth logins to third party systems hosting the Materials. Accordingly, when
            you use the Application, you will be providing us with access to
            all personal information included within any Materials you provide in this manner.
        </p>

        <p>
            You are in control of whether you provide us with any personal information. If you do not wish to provide
            particular information, you should not use the Application or
            provide your information. That will affect our ability to do business with you; however, that is your
            choice.
        </p>

        <p>
            We will use the personal information included in the Materials to evaluate your potential business
            relationship with us, including to accurately identify you and your
            business, to understand your business and financial history, and to make underwriting decisions. We may
            combine the personal information included in the Materials with
            information collected from other sources, such as information that you provide us outside the Application,
            and information we receive from other sources.
        </p>

        <p>
            We keep the personal information we collect for as long as we continue to have a business need to do so
            (which will be at least as long as you continue to have a prospective
            or ongoing client relationship with us), unless a longer period is required by law. Business needs include
            legal, tax, accounting, risk management, and other business
            purposes.
        </p>

        <h5>3. HOW AND WHY WE SHARE PERSONAL INFORMATION</h5>

        <p>
            We share personal information with third parties in the following circumstances:
        </p>

        <ol>
            <li style={{listStyleType: 'disc'}}>
                <p>
                    We will provide personal information to our service providers who need to use that information to
                    conduct business on our behalf. For example, our service providers may
                    assist us with operation of the Application, may store our documentation, or may handle data
                    management. We provide these service providers only with the information they
                    need to perform their services, and work with them to respect and protect your privacy.
                </p>
            </li>

            <li style={{listStyleType: 'disc'}}>
                <p>
                    We may share your personal information with our company affiliates and business partners for our and
                    their legitimate business purposes, including so that we or they may
                    provide you with periodic mailings regarding products and services. If you do not wish for us to
                    share your information in this manner, please let us know by contacting
                    us
                    at <a href="mailto:hello@assembledbrands.com">hello@assembledbrands.com</a>.
                </p>
            </li>

            <li style={{listStyleType: 'disc'}}>
                <p>
                    We will release information, including personal information, if we believe that doing so is
                    appropriate to (a) comply with law, regulations, legal process or governmental
                    request, (b) enforce any policies applicable to the Application, including potential violations, (c)
                    detect, prevent or otherwise address fraud, security or technical
                    issues, or (d) otherwise protect the rights, property or personal safety of us, our employees,
                    Application users, or others.
                </p>
            </li>

            <li style={{listStyleType: 'disc'}}>
                <p>
                    We may share information, including personal information, in connection with, during negotiations
                    of, or as a part of the closing of, any merger, sale of company assets,
                    financing or acquisition of all or a portion of our business to another company in which personal
                    information about the users of the Application is among the assets
                    transferred.
                </p>
            </li>

        </ol>

        <p>
            We do not sell, rent, or lease any personal information that we collect about you through the Application to
            anyone for any purpose, other than as disclosed, unless you have
            given us your separate express permission to do so.
        </p>

        <h5>4. HOW WE COLLECT AND USE NON-PERSONALLY IDENTIFIABLE INFORMATION</h5>

        <p>
            Non-personally identifiable information, also called NPII, refers to information that is not and cannot be
            used, by itself, to identify a specific individual. When you use
            the Application, we may automatically collect standard internet and website log information, and details of
            patterns about how our Application users behave. This information
            may include your internet service provider name, your operating system, your browser type and browser
            language, and your access times. More information about the technology
            we use to automatically collect information is provided below under the heading “Cookies and Other Tracking
            Technology.”
        </p>

        <p>
            All of the NPII we collect may be used to understand our prospective and ongoing client base, to gather
            broad demographic data for aggregate use, to conduct market research,
            to analyze trends, to develop products and services, and to provide those products and services to others.
        </p>

        <p>
            If we associate any NPII with the personal information you provide us, we will treat the associated
            information as personal information, in accordance with this Privacy
            Notice.
        </p>

        <h5>5. COOKIES AND OTHER TRACKING TECHNOLOGY</h5>

        <p>
            Cookies are small amounts of data that are stored on your browser, device, or the page you are viewing when
            you visit a website. Some cookies are deleted once you close your
            browser or application (session cookies), while other cookies are retained even after you exit so that you
            can be recognized when you return (persistent cookies). Cookies do
            not typically contain any information that personally identifies a user, but personal information that we
            store about you may be linked to the information stored in and
            obtained from cookies.
        </p>

        <p>
            We use both session and persistent cookies in connection with the Application. We use these to help us
            recognize you as you use or return to the Application, so that we can
            provide a more personalized experience.
        </p>

        <p>
            Most web browsers automatically accept cookies and other tracking technologies, but you can usually change
            your settings to prevent that, such as by having your device warn
            you each time a cookie is being sent or choosing to turn off all cookies. On our Application, you will be
            able to disable all cookies other than essential cookies required
            for the Application to function. This can be done through your web browser settings, and because each
            browser is different, you should look at your browser’s “Help” menu to
            learn how to modify your cookie settings. If you do disable cookies from your browser you may not be able to
            access certain features of the Application, and this may make
            your use of the Application less efficient.
        </p>

        <p>
            Third parties, including our analytics providers, may use cookies, alone or with web beacons or other
            tracking technology, to collect information about you when you use the
            Application for their own purposes, independent from us. These third parties may be able to associate the
            information they collect with your personal information, or they may
            collect information, including personal information, about your online activities over time and across other
            website and online services. They may use this information to
            provide you with behavioral advertising or other targeted content on other websites and through other media.
            We cannot control any third parties’ tracking technologies or how
            they may be used. If you have any questions about an advertisement or other targeted content, you should
            contact the responsible provider directly.
        </p>

        <p>
            Currently, certain browsers, including Firefox, Google Chrome, Safari and Internet Explorer, offer a “do not
            track” (DNT) option. This option, using a technology known as a
            DNT header, sends a signal to websites visited by the user about the user’s DNT preference, if any, set on
            the browser. We do not currently commit to responding to browsers’
            DNT signals. We cannot make this commitment because no common industry standard for DNT has been adopted by
            industry groups, technology companies, or regulators.
        </p>

        <h5>6. OUR SECURITY MEASURES</h5>

        <p>
            We use a variety of industry-standard security measures to maintain the safety of your personal information,
            including measures designed to secure your personal information
            from accidental loss and from unauthorized access, use, alteration and disclosure. Unfortunately, no
            electronic data transmission over the Internet can be guaranteed to be
            100% secure. As a result, while we believe that we use reasonable measures to protect personal information,
            we cannot ensure or warrant the security of any information
            transmitted or otherwise provided to us or received from us.
        </p>

        <h5>7. YOUR RIGHTS REGARDING YOUR INFORMATION</h5>

        <p>
            Opting Out of Certain Communications. You can always opt out of receiving promotional communications from us
            by contacting us at us at <a
            href="mailto:hello@assembledbrands.com">hello@assembledbrands.com</a>. Please note that notwithstanding your
            email preferences, we may need to contact you regarding our
            potential or ongoing business relationship, as required by law, or regarding legal matters. However, we will
            not send you marketing communications after you have opted out of
            receiving them.
        </p>

        <p>
            Correcting and Updating Information. If you believe that any of the personal information we have about you
            is incorrect, you can contact us at any time
            at <a href="mailto:hello@assembledbrands.com">hello@assembledbrands.com</a> to request that we make the
            appropriate corrections.
        </p>

        <p>
            Removal of Information. As noted above, we have standard retention practices with respect to the personal
            data we collect. If you would like to request any earlier removal of
            your personal information, please contact us at <a
            href="mailto:hello@assembledbrands.com">hello@assembledbrands.com</a>. Please note that we may not be able
            to completely
            remove personal information from the Application or our systems in certain circumstances. This will be true
            if the data is not in a searchable format, if it is retained in
            backup systems, if we need it in order to prevent fraud or future abuse, or if we are required by law to
            keep it.
        </p>

    </div>
)
export default PrivacyNotice